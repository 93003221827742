const formatData = (income, savingsAndInvestment, variableExp, fixedExp) => {
  let data = []
  if (income.isSelected && Array.isArray(income.categories))
    data = [...data, ...income.categories]
  if (
    savingsAndInvestment.isSelected &&
    Array.isArray(savingsAndInvestment.categories)
  )
    data = [...data, ...savingsAndInvestment.categories]
  if (variableExp.isSelected && Array.isArray(variableExp.categories))
    data = [...data, ...variableExp.categories]
  if (fixedExp.isSelected && Array.isArray(fixedExp.categories))
    data = [...data, ...fixedExp.categories]
  return data
}

export { formatData }
