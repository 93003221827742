import { Typography } from "@mui/material"
import React from "react"

const Mobile = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
      }}
    >
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        Our mobile site is under development and not yet available. Please try{" "}
        <b>https://wwww.CashEdPreview.com</b> on a computer.
      </Typography>
    </div>
  )
}

export default Mobile
