import { useEffect } from "react"
import fetcher from "../utils/fetchAuthorize"
import Box from "@mui/material/Box"
import { useRecoilState, useResetRecoilState } from "recoil"
import classes from "./DashboardTest.module.css"
import PieChart from "./PieChart"
import {
  incomeState,
  savingsAndInvestmentState,
  variableExpState,
  fixedExpState,
  highlightedCategoryState
} from "../state/state"
import BarGraph from "./BarGraph"
import CategoryList from "./CategoryList"
import { setCategoryTotalAndCategories } from "../state/stateUtils"
import MonthSelector from "./MonthSelector"
import { formatData } from "../utils/pieChart.utils"
import { baseURL } from "../properties"

const DashboardTest = () => {
  const [income, setIncome] = useRecoilState(incomeState)
  const [savingsAndInvestment, setSavingsAndInvestment] = useRecoilState(
    savingsAndInvestmentState
  )
  const [variableExp, setVariableExp] = useRecoilState(variableExpState)
  const [fixedExp, setFixedExp] = useRecoilState(fixedExpState)
  const resetHighlightedCatState = useResetRecoilState(highlightedCategoryState)
  // const resetIncomeState = useResetRecoilState(incomeState)
  // const resetSavingsAndInvestmentState = useResetRecoilState(
  //   savingsAndInvestmentState
  // )
  // const resetVariableExpState = useResetRecoilState(variableExpState)
  // const resetFixedExpState = useResetRecoilState(fixedExpState)

  useEffect(() => {
    const fetchData = async () => {
      const date = new Date()
      const currentMonthParams = new URLSearchParams({
        month: date.getMonth() + 1,
        year: date.getFullYear()
      })
      try {
        const currentMonthResponse = await fetcher(
          `${baseURL}getTest2?${currentMonthParams}`
        )

        if (!currentMonthResponse.ok) {
          throw new Error("Network response was not ok")
        }
        let currentMonthData = await currentMonthResponse.json()
        const prevMonthParams = new URLSearchParams({
          month: date.getMonth() === 0 ? 12 : date.getMonth(),
          year:
            date.getMonth === 0 ? date.getFullYear() - 1 : date.getFullYear()
        })

        const previousMonthResponse = await fetcher(
          `${baseURL}getTest2?${prevMonthParams}`
        )

        if (!previousMonthResponse.ok) {
          throw new Error("Network response was not ok")
        }
        let previousMonthData = await previousMonthResponse.json()

        currentMonthData.map((item) => {
          item.value = parseInt(item.total)
          item.label = item.name

          const prevItem = previousMonthData.find(
            (prevItem) => prevItem.id === item.id
          )
          item.previousTotal = prevItem ? prevItem.total : 0

          return item
        })

        return currentMonthData
      } catch (error) {
        console.error("Error fetching data: ", error)
        return []
      }
    }
    fetchData().then((data) => {
      setIncome(setCategoryTotalAndCategories(income, data))
      setSavingsAndInvestment(
        setCategoryTotalAndCategories(savingsAndInvestment, data)
      )
      setVariableExp(setCategoryTotalAndCategories(variableExp, data))
      setFixedExp(setCategoryTotalAndCategories(fixedExp, data))
    })

    return () => {
      resetHighlightedCatState()
      // resetIncomeState()
      // resetSavingsAndInvestmentState()
      // resetVariableExpState()
      // resetFixedExpState()
    }
  }, [])

  const data = formatData(income, savingsAndInvestment, variableExp, fixedExp)

  return (
    <Box className={classes.container}>
      <CategoryList />
      <Box>
        <MonthSelector useCurrentMonth />
        <PieChart data={data} />
      </Box>
      <BarGraph layout="vertical" />
    </Box>
  )
}

export default DashboardTest
