import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { usdFormatter } from "../utils/usdFormatter"
import classes from "./BarGraph.module.css"
import { ResponsiveBar } from "@nivo/bar"
import { useRecoilValue } from "recoil"
import { highlightedCategoryState } from "../state/state"

const BarGraph = ({ layout }) => {
  const highlightedCategory = useRecoilValue(highlightedCategoryState)

  // useEffect(() => {
  //   if (layout === "horizontal") {
  //     if (highlightedCategory) {
  //       const titleHeight =
  //         document.getElementById("horizontalTitle").offsetHeight
  //     }
  //   }
  // }, [highlightedCategory])

  const dimensions =
    layout === "vertical"
      ? { height: "calc(100vh - 120px)", width: "300px" }
      : { height: "120px", width: "calc(100vw - 250px)" }
  const tooltip = ({ id, value, indexValue }) => {
    return (
      <Box>
        <Typography variant="h6">{indexValue}</Typography>
        <Typography variant="h6">{usdFormatter(value)}</Typography>
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      {highlightedCategory && (
        <>
          <>
            {layout === "horizontal" && (
              <Typography
                id="horizontalTitle"
                variant="h6"
                className={classes.horizontalTitle}
              >
                {highlightedCategory.name}
              </Typography>
            )}
          </>

          <Box>
            {layout === "vertical" && (
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                {highlightedCategory.name}
              </Typography>
            )}
            <Box sx={dimensions}>
              <ResponsiveBar
                data={[
                  {
                    label: "Current Total",
                    amount: highlightedCategory.total
                  },
                  {
                    label: "Previous Month",
                    previousAmount: highlightedCategory.previousTotal
                  },
                  {
                    label: "Goal Total",
                    goalAmount: highlightedCategory.total + 100
                  }
                ]}
                keys={["amount", "previousAmount", "goalAmount"]}
                indexBy="label"
                layout={layout}
                colors={highlightedCategory.colors}
                colorBy="indexValue"
                margin={{ top: 10, right: 0, bottom: 40, left: 0 }}
                padding={0.4}
                tooltip={tooltip}
                enableLabel={false}
                enableGridY={false}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 10,
                  tickRotation: 15,
                  legendPosition: "middle",
                  truncateTickAt: 0
                }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#fff7e7",
                    size: 4,
                    padding: 1,
                    stagger: true
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#fff7e7",
                    rotation: -45,
                    lineWidth: 4,
                    spacing: 10
                  }
                ]}
                fill={[
                  {
                    match: {
                      id: "previousAmount"
                    },
                    id: "dots"
                  },
                  {
                    match: {
                      id: "goalAmount"
                    },
                    id: "lines"
                  }
                ]}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default BarGraph
