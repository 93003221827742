import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import classes from "./SandboxAccountsAlert.module.css"

const SandboxAccountsAlert = () => {
  return (
    <>
      <Alert severity="info" className={classes.container}>
        <AlertTitle>
          <b>Sandbox Mode</b>
        </AlertTitle>
        Username: &nbsp;&nbsp;
        <span className={classes.accountInfo}>
          <em>
            <b>user_good</b>
          </em>{" "}
        </span>
        &nbsp;&nbsp; Password: &nbsp;&nbsp;&nbsp;
        <span className={classes.accountInfo}>
          <em>
            <b>pass_good</b>
          </em>
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Code:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span className={classes.accountInfo}>
          <em>
            <b>1234</b>
          </em>
        </span>
      </Alert>
    </>
  )
}

export default SandboxAccountsAlert
