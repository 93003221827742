import { NavLink, useLoaderData, useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import classes from "./HeaderLayout.module.css"
import DrawerLayout from "./DrawerLayout"
import fetcher from "../../utils/fetchAuthorize"
import FetchError from "../../utils/fetchError"
import { useEffect } from "react"
import { Typography } from "@mui/material"
import { baseURL } from "../../properties"

/**
 * @async
 * @function headerNotificationsLoader
 * @description Fetches the unread notification counts for the current user from the server.
 * @returns {Promise<Array>|boolean} - Returns an array of notification counts if the user is authenticated, otherwise returns false.
 */
const headerNotificationsLoader = async () => {
  if (localStorage.jwt) {
    try {
      const response = await fetcher(
        `${baseURL}getUnreadNotificationsByUserByCategory`
      )
      if (!response.ok) throw new FetchError.fromResponse(response)
      else if (response.status === 200) {
        return await response.json()
      }
    } catch (error) {
      // Handle a server side error
      if (error instanceof FetchError) {
        console.log("fetch error")
      }
      // return false if the user is not authenticated
      return false
    }
  }
  return false
}

export { headerNotificationsLoader }

/**
 * @description Renders the header component that allows the user to navigate through the application. Redirects the user to the landing page if they are not authenticated.
 * @example <Header />
 * @returns {JSX.Element} The JSX element representing the header navigation bar.
 */
const HeaderLayout = () => {
  const navigate = useNavigate()
  const isAuthenticated = useLoaderData()

  useEffect(() => {
    // If the user is not authenticated, send them to the landing (home) page
    if (isAuthenticated === false) {
      localStorage.removeItem("jwt")
      navigate("/home")
    }
  })

  return (
    // add confirmation functionality to prevent users from navigating without saving changes first
    <Box className={classes.container}>
      <Stack
        className={classes.list}
        justifyContent="center"
        alignItems="center"
        direction="row"
        spacing={6}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Typography variant="button">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Dashboard
          </NavLink>
        </Typography>
        <Typography variant="button">
          <NavLink
            to="TransactionImport"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Transaction Import
          </NavLink>
        </Typography>

        <Typography variant="button">
          <NavLink
            to="budget" //TODO: change to budget when the page is ready
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Budget
          </NavLink>
        </Typography>

        <Typography variant="button">
          <NavLink
            to="accounts"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Accounts
          </NavLink>
        </Typography>

        <Typography variant="button">
          <NavLink
            to="AboutUs"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            About Us
          </NavLink>
        </Typography>
        <Typography variant="button">
          <NavLink
            to="contact"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Contact Us
          </NavLink>
        </Typography>

        <DrawerLayout />
      </Stack>
    </Box>
  )
}

export default HeaderLayout
