import Box from "@mui/material/Box"
import FormHeader from "../../components/auth/FormHeader"
import LoginForm from "../../components/auth/LoginForm"
import classes from "./Auth.module.css"
import SandboxAlert from "../../components/SandboxAlert"

/**
 * @returns {JSX.Element} Login page
 */
const Login = () => {
  return (
    <Box className={classes.container}>
      <SandboxAlert />
      <Box className={classes.formContainer}>
        <FormHeader pageTitle={"Login"} />
        <LoginForm />
      </Box>
    </Box>
  )
}

export default Login
