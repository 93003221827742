import { useState } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import classes from "./MonthSelector.module.css"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs from "dayjs"

const MonthSelector = ({
  useCurrentMonth,
  setMonthHandler,
  setYearHandler
}) => {
  const [value, setValue] = useState(dayjs())

  const handleChange = (newValue) => {
    setValue(newValue)

    setMonthHandler(newValue.month() + 1)
    setYearHandler(newValue.year())
  }

  return (
    <Box className={classes.container}>
      {(useCurrentMonth && (
        <Typography variant="h4">{value.format("MMMM")}</Typography>
      )) || (
        <DatePicker
          views={["month", "year"]}
          label="Select Month and Year"
          value={value}
          onChange={handleChange}
          slotProps={{ textField: { size: "small", variant: "outlined" } }}
        />
      )}
    </Box>
  )
}

export default MonthSelector
