export const setObjectBoolean = (state, key, value) => {
  return {
    ...state,
    [key]: value
  }
}
export const setCategoryTotalAndCategories = (state, data) => {
  let total = 0.0
  let counter = 0
  const categories = data
    .filter((item) => {
      if (item.categoryId === state.id) {
        total += item.total
        counter += state.opacityMultiplier
      }
      return item.categoryId === state.id
    })
    .map((item, index) => {
      const opacity = 1 - ((1 / counter) * index).toFixed(2)
      item.color = `rgba(${state.colorValues[0]}, ${state.colorValues[1]}, ${state.colorValues[2]}, ${opacity})`
      item.hoverColor = `rgba(${state.colorValues[0]}, ${
        state.colorValues[1]
      }, ${state.colorValues[2]}, ${opacity - 0.1})`
      item.barColor = state.hexColor
      return item
    })

  return {
    ...state,
    total: total,
    categories: categories
  }
}
