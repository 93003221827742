import { useEffect } from "react"
import List from "@mui/material/List"
import classes from "./CategoryList.module.css"
import Box from "@mui/material/Box"
import SubcategoryList from "./SubcategoryList"
import { useRecoilState, useSetRecoilState } from "recoil"
import {
  highlightedCategoryState,
  incomeState,
  savingsAndInvestmentState,
  variableExpState,
  fixedExpState
} from "../state/state"

const CategoryList = () => {
  const setHighlightedCategory = useSetRecoilState(highlightedCategoryState)
  const [income, setIncome] = useRecoilState(incomeState)
  const [savingsAndInvestment, setSavingsAndInvestment] = useRecoilState(
    savingsAndInvestmentState
  )
  const [variableExp, setVariableExp] = useRecoilState(variableExpState)
  const [fixedExp, setFixedExp] = useRecoilState(fixedExpState)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setHighlightedCategory(null)
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <Box className={classes.container}>
      <List className={classes.list}>
        <SubcategoryList category={income} setter={setIncome} />
        <SubcategoryList
          category={savingsAndInvestment}
          setter={setSavingsAndInvestment}
        />
        <SubcategoryList category={variableExp} setter={setVariableExp} />
        <SubcategoryList category={fixedExp} setter={setFixedExp} />
      </List>
    </Box>
  )
}

export default CategoryList
