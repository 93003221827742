import { useState, useEffect } from "react"

function useWindowSize() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener("resize", handleResize)
    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty dependency array ensures this effect runs only on mount and unmount

  return size
}

export default useWindowSize
