import { useEffect, useState } from "react"
import fetcher from "../../utils/fetchAuthorize"
import Box from "@mui/material/Box"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import classes from "./Budget.module.css"
import PieChart from "../PieChart"
import {
  incomeState,
  savingsAndInvestmentState,
  variableExpState,
  fixedExpState,
  highlightedCategoryState
} from "../../state/state"
import BarGraph from "../BarGraph"
import CategoryList from "../CategoryList"
import { setCategoryTotalAndCategories } from "../../state/stateUtils"
import MonthSelector from "../MonthSelector"
import TransactionsList from "../transactions/TransactionsList"
import { formatData } from "../../utils/pieChart.utils"
import { baseURL } from "../../properties"

const Budget = () => {
  const [month, setMonth] = useState(() => new Date().getMonth() + 1)
  const [year, setYear] = useState(() => new Date().getFullYear())
  const [income, setIncome] = useRecoilState(incomeState)
  const [savingsAndInvestment, setSavingsAndInvestment] = useRecoilState(
    savingsAndInvestmentState
  )
  const [variableExp, setVariableExp] = useRecoilState(variableExpState)
  const [fixedExp, setFixedExp] = useRecoilState(fixedExpState)
  const highlightedCategory = useRecoilValue(highlightedCategoryState)
  const resetHighlightedCatState = useResetRecoilState(highlightedCategoryState)
  // const resetIncomeState = useResetRecoilState(incomeState)
  // const resetSavingsAndInvestmentState = useResetRecoilState(
  //   savingsAndInvestmentState
  // )
  // const resetVariableExpState = useResetRecoilState(variableExpState)
  // const resetFixedExpState = useResetRecoilState(fixedExpState)

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams({
        month: month,
        year: year
      })
      try {
        const currentMonthResponse = await fetcher(
          `${baseURL}getSubcategoriesByMonthWithTransactions?${params}`
        )

        if (!currentMonthResponse.ok) {
          throw new Error("Network response was not ok")
        }
        let currentMonthData = await currentMonthResponse.json()
        const prevMonthParams = new URLSearchParams({
          month: month === 1 ? 12 : month - 1,
          year:
            month === 1
              ? new Date().getFullYear() - 1
              : new Date().getFullYear()
        })

        const previousMonthResponse = await fetcher(
          `${baseURL}getTest2?${prevMonthParams}`
        )

        if (!previousMonthResponse.ok) {
          throw new Error("Network response was not ok")
        }
        let previousMonthData = await previousMonthResponse.json()

        currentMonthData.map((item) => {
          item.value = parseInt(item.total)
          item.label = item.name

          const prevItem = previousMonthData.find(
            (prevItem) => prevItem.id === item.id
          )
          item.previousTotal = prevItem ? prevItem.total : 0

          return item
        })

        return currentMonthData
      } catch (error) {
        console.error("Error fetching data: ", error)
        return []
      }
    }
    fetchData().then((data) => {
      setIncome(setCategoryTotalAndCategories(income, data))
      setSavingsAndInvestment(
        setCategoryTotalAndCategories(savingsAndInvestment, data)
      )
      setVariableExp(setCategoryTotalAndCategories(variableExp, data))
      setFixedExp(setCategoryTotalAndCategories(fixedExp, data))
    })

    return () => {
      resetHighlightedCatState()
      // resetIncomeState()
      // resetSavingsAndInvestmentState()
      // resetVariableExpState()
      // resetFixedExpState()
    }
  }, [month, year])

  const data = formatData(income, savingsAndInvestment, variableExp, fixedExp)

  return (
    <Box className={classes.container}>
      <Box className={classes.subContainer}>
        <CategoryList />
        <Box>
          <MonthSelector setMonthHandler={setMonth} setYearHandler={setYear} />
          <PieChart data={data} isBudget="true" />
        </Box>
        <Box className={classes.transactionListContainer}>
          {highlightedCategory && (
            <TransactionsList transactions={highlightedCategory.transactions} />
          )}
        </Box>
      </Box>
      <BarGraph layout="horizontal" />
    </Box>
  )
}

export default Budget

/* const Budget = () => {
  const [dialogDetails, setDialogDetails] = useState({
    isOpen: false,
    categoryId: 1
  })
  const [isExpanded, setIsExpanded] = useState(false)

  // classes used for styling the card when expanded
  const expandClasses = `${classes["expandMore"]} ${
    isExpanded ? classes["expandMoreExpanded"] : ""
  }`

  const openDialogHandler = (categoryId) => {
    setDialogDetails({ isOpen: true, categoryId: categoryId })
    setIsExpanded(false)
  }
  const setIsOpenHandler = (isOpen) => {
    setDialogDetails({ isOpen: isOpen, categoryId: 1 })
  }
  return <Box></Box>
}
export default Budget

{
  <Button
        variant="outlined"
        onClick={() => setIsExpanded(!isExpanded)}
        aria-expanded={isExpanded}
        aria-label="show more"
        sx={{ color: "black", borderColor: "black" }}
      >
        Update Categories <ExpandMoreIcon className={expandClasses} />
      </Button>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List>
          <ListItem>
            <Button
              variant="contained"
              color="income"
              onClick={() => openDialogHandler(1)}
            >
              Income
            </Button>
          </ListItem>
          <ListItem>
            <Button
              variant="contained"
              color="savings"
              onClick={() => openDialogHandler(2)}
            >
              Savings & Investments
            </Button>
          </ListItem>
          <ListItem>
            <Button
              variant="contained"
              color="variable"
              onClick={() => openDialogHandler(3)}
            >
              Variable Expenditures
            </Button>
          </ListItem>
          <ListItem>
            <Button
              variant="contained"
              color="fixed"
              onClick={() => openDialogHandler(4)}
            >
              Fixed Expenditures
            </Button>
          </ListItem>
        </List>
      </Collapse>

      <TransactionImportAddCategory
        isOpen={dialogDetails.isOpen}
        setIsOpen={setIsOpenHandler}
        categoryId={dialogDetails.categoryId}
        //   setSubcategoriesHandler={setSubcategories}
      />

      <Button variant="contained">Set Category Goals</Button> */
