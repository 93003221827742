import classes from "./AboutUsBio.module.css"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import AboutUsWrapper from "./AboutUsWrapper"

/**
 * @description The bio component used in the About Us page to describe CasedEd as an application
 * @returns {JSX.Element} The JSX code for the component that will be displayed in the browser
 */
const AboutUsBio = () => {
  const information = [
    {
      header: "Lorem ipsum dolor sit amet consectetur",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisi scelerisque eu ultrices vitae auctor eu augue ut. Feugiat sed lectus vestibulum mattis ullamcorper."
    },
    {
      header:
        "qui officia deserunt mollit anim id est laborum. Nisi scelerisque eu ultrices ",
      text: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Nisi scelerisque eu ultrices vitae auctor eu augue ut. Feugiat sed lectus vestibulum mattis ullamcorper."
    },
    {
      header: "Nisi scelerisque eu ultrices vitae auctor eu augue ut",
      text: "Excepteur sint occaecat cupidatat non proident, sunt in culpalaborum. Nisi scelerisque eu ultrices vitae auctor eu augue ut. Feugiat sed letis ullamcorper."
    },
    {
      header:
        "ullamco laboris commodo consequat nisi ut aliquip ex ea commodo consequat",
      text: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
    }
  ]
  return (
    <AboutUsWrapper size="60vh">
      <Stack spacing={1} alignItems="center" className={classes.stack}>
        {information.map((info, index) => (
          <div key={index}>
            <Typography
              variant="body1"
              color="primary.text"
              sx={{ fontWeight: "bold" }}
            >
              {info.header}
            </Typography>
            <Typography variant="body2" color="primary.text">
              {info.text}
            </Typography>
          </div>
        ))}
      </Stack>
    </AboutUsWrapper>
  )
}

export default AboutUsBio
