import HeaderLayout from "../components/header/HeaderLayout"
import { Outlet, useNavigate } from "react-router-dom"
import { useEffect } from "react"

const Header = () => {
  const navigate = useNavigate()

  useEffect(() => {
    function isMobileDevice() {
      return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    }

    if (isMobileDevice()) {
      navigate("/mobile")
    }
  }, [navigate])
  return (
    <>
      <HeaderLayout />
      <Outlet />
    </>
  )
}

export default Header
