import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import classes from "./SandboxAlert.module.css"

const SandboxAlert = ({ isRegister }) => {
  const Content = () => {
    return isRegister ? (
      <>
        <>
          <b>Head to the Login Page to try our demo account</b> <em>OR</em>{" "}
          register a new account here.
        </>
      </>
    ) : (
      <>
        <b>Try us out -</b>&nbsp;&nbsp; username:{" "}
        <em>
          <b>user</b>
        </em>{" "}
        &nbsp;&nbsp;&nbsp;password:{" "}
        <em>
          <b>password</b>
        </em>
      </>
    )
  }

  return (
    <>
      <Alert severity="info" className={classes.container}>
        <AlertTitle>
          <b>Sandbox Mode</b>
        </AlertTitle>
        This site is currently under development and available for demonstration
        purposes only.
        <br />
        <span className={classes.userInfo}>{<Content />}</span>
      </Alert>
    </>
  )
}

export default SandboxAlert
