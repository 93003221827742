import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import { Droppable } from "react-beautiful-dnd"
import classes from "./TransactionSubcategory.module.css"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { usdFormatter } from "../../utils/usdFormatter"
import { styled } from "@mui/material/styles"

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    fontWeight: "bold"
  }
}))

const TransactionSubcategory = ({
  subcategory,
  isActive,
  setActiveSubcategory,
  total
}) => {
  const color = isActive
    ? "rgba(255, 255, 255, 0.45)"
    : "rgba(255, 255, 255, 0.9)"

  return (
    <Droppable
      droppableId={
        subcategory.id.toString() + "," + subcategory.categoryId.toString()
      }
    >
      {(provided, snapshot) => (
        <StyledTooltip
          title={subcategory.name}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -40]
                  }
                }
              ]
            }
          }}
          placement="left"
          sx={
            {
              // backgroundColor: "red"
            }
          }
        >
          <Card
            onClick={() => setActiveSubcategory(subcategory)}
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classes.container}
            style={{
              "--color": color
            }}
          >
            <CardContent className={classes.cardContent}>
              <Typography variant="body1">{subcategory.name}</Typography>
              <Typography variant="body1">{usdFormatter(total)}</Typography>
              {provided.placeholder}
            </CardContent>
          </Card>
        </StyledTooltip>
      )}
    </Droppable>
  )
}

export default TransactionSubcategory
