import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { usdFormatter } from "../utils/usdFormatter"
import classes from "./PieChart.module.css"
import { ResponsivePie } from "@nivo/pie"
import { useRecoilState } from "recoil"
import { activeIdState, highlightedCategoryState } from "../state/state"
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"

const PieChart = ({ data, isBudget }) => {
  const [isBlank, setIsBlank] = useState(false)
  const [activeId, setActiveId] = useRecoilState(activeIdState)
  const [highlightedCategory, setHighlightedCategory] = useRecoilState(
    highlightedCategoryState
  )

  useEffect(() => {
    if (data.length === 0) {
      setIsBlank(true)
    } else {
      setIsBlank(false)
    }
  }, [data])
  const colors = data.map((item) => item.color)

  const idToHighlight = highlightedCategory
    ? highlightedCategory.id
    : parseInt(activeId)

  const handleActiveIdChange = (id) => {
    setActiveId(id)
  }

  const clickHandler = (node) => {
    if (highlightedCategory !== null && highlightedCategory.id === activeId) {
      // If clicking the already active node, deactivate it
      setHighlightedCategory(null)
    } else {
      // Otherwise, update highlightedCategory and let onActiveIdChange handle setting activeId
      setHighlightedCategory({
        id: node.id,
        name: node.label,
        total: node.value,
        previousTotal: node.data.previousTotal,
        colors: node.data.barColor,
        transactions: node.data.transactions ?? []
      })
    }
  }
  const tooltip = ({ datum }) => {
    return (
      <Box>
        <Typography variant="h6">{datum.label}</Typography>
        <Typography variant="h6">{usdFormatter(datum.value)}</Typography>
      </Box>
    )
  }
  const dimensions = isBudget
    ? { width: "calc(100vw - 650px)", height: "calc(100vh - 160px)" }
    : { width: "calc(100vw - 715px)", height: "calc(100vh - 105px)" }

  return (
    <Box className={classes.container} sx={dimensions}>
      {(isBlank && (
        <Typography variant="h6" className={classes.message}>
          You have not recorded any transactions yet this month. Head to
          <em>
            <b>
              <Typography
                variant="h5"
                color="primary"
                sx={{ display: "inline" }}
              >
                <NavLink to="/TransactionImport"> TRANSACTION IMPORT </NavLink>
              </Typography>
            </b>
          </em>
          to begin.
        </Typography>
      )) || (
        <ResponsivePie
          data={data}
          innerRadius={0.65}
          activeId={idToHighlight}
          onActiveIdChange={handleActiveIdChange}
          padAngle={0.5}
          cornerRadius={2}
          activeInnerRadiusOffset={10}
          activeOuterRadiusOffset={16}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          onClick={clickHandler}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          motionConfig="wobbly"
          tooltip={tooltip}
          colors={colors}
        />
      )}
    </Box>
  )
}

export default PieChart
