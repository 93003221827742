import { Outlet, useNavigate } from "react-router-dom"
import footer from "../assets/AuthFooter.png"
import classes from "./LandingWrapper.module.css"
import LandingHeader from "../components/header/LandingHeader"
import { useEffect } from "react"

const LandingWrapper = () => {
  const navigate = useNavigate()

  useEffect(() => {
    function isMobileDevice() {
      return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    }

    if (isMobileDevice()) {
      navigate("/mobile")
    }
  }, [navigate])

  return (
    <div className={classes.wrapper}>
      <LandingHeader />
      <Outlet />
      <img alt="footer" src={footer} className={classes.footer} />
    </div>
  )
}

export default LandingWrapper
