import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import { NavLink } from "react-router-dom"
import classes from "./LandingHeader.module.css"

/**
 * @description Renders the header component when the user is not authenticated.
 * @example <LandingHeader />
 * @returns {JSX.Element} The JSX element representing the landing header.
 */
const LandingHeader = () => {
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Typography variant="h5" className={classes.title}>
          CashEd
        </Typography>
        <Typography variant="h6" className={classes.title}>
          The Finance Tracker
        </Typography>
      </div>
      <Stack
        direction="row"
        spacing={3}
        divider={
          <Divider className={classes.navLinkDivider} orientation="vertical" />
        }
        justifyContent="center"
        alignItems="center"
        className={classes.links}
      >
        <Typography variant="button">
          <NavLink
            className={({ isActive }) =>
              `${classes.navLink} ${isActive ? classes.active : ""}`
            }
            to="/home"
          >
            Home
          </NavLink>
        </Typography>
        <Typography variant="button">
          <NavLink
            className={({ isActive }) =>
              `${classes.navLink} ${isActive ? classes.active : ""}`
            }
            to="/aboutUs"
          >
            About Us
          </NavLink>
        </Typography>
        <Typography variant="button">
          <NavLink
            className={({ isActive }) =>
              `${classes.navLink} ${isActive ? classes.active : ""}`
            }
            to="/contact"
          >
            Contact
          </NavLink>
        </Typography>
        {(localStorage.jwt && (
          <Typography variant="button">
            <NavLink
              className={({ isActive }) =>
                `${classes.navLink} ${isActive ? classes.active : ""}`
              }
              to="/"
            >
              Dashboard
            </NavLink>
          </Typography>
        )) || (
          <Typography variant="button">
            <NavLink
              className={({ isActive }) =>
                `${classes.navLink} ${isActive ? classes.active : ""}`
              }
              to="/login"
            >
              Login
            </NavLink>
          </Typography>
        )}
        {!localStorage.jwt && (
          <Typography variant="button">
            <NavLink
              className={({ isActive }) =>
                `${classes.navLink} ${isActive ? classes.active : ""}`
              }
              to="/register"
            >
              Register
            </NavLink>
          </Typography>
        )}
      </Stack>
    </div>
  )
}
export default LandingHeader
