import { atom } from "recoil"

export const activeIdState = atom({
  key: "activeIdState", // unique ID (with respect to other atoms/selectors)
  default: null // default value (aka initial value)
})

export const highlightedCategoryState = atom({
  key: "highlightedCategoryState",
  default: null
})

export const incomeState = atom({
  key: "incomeState",
  default: {
    id: 1,
    name: "Income",
    isSelected: false,
    isExpanded: false,
    categories: [],
    total: 0,
    opacityMultiplier: 1.4,
    colorValues: [23, 195, 178],
    hexColor: "#17c3b2"
  }
})
export const savingsAndInvestmentState = atom({
  key: "savingsAndInvestmentState",
  default: {
    id: 2,
    name: "Savings & Investments",
    isSelected: true,
    isExpanded: false,
    categories: [],
    total: 0,
    opacityMultiplier: 1.5,
    colorValues: [34, 124, 157],
    hexColor: "#227c9d"
  }
})

export const variableExpState = atom({
  key: "variableExpState",
  default: {
    id: 3,
    name: "Variable Expenditures",
    isSelected: true,
    isExpanded: false,
    categories: [],
    total: 0,
    opacityMultiplier: 1.75,
    colorValues: [255, 203, 119],
    hexColor: "#ffcb77"
  }
})

export const fixedExpState = atom({
  key: "fixedExpState",
  default: {
    id: 4,
    name: "Fixed Expenditures",
    isSelected: true,
    isExpanded: false,
    categories: [],
    total: 0,
    opacityMultiplier: 1.6,
    colorValues: [254, 109, 115],
    hexColor: "#fe6d73"
  }
})
