import { useState, useRef } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import { useLoaderData } from "react-router-dom"
import Dialog from "@mui/material/Dialog"
import Checkbox from "@mui/material/Checkbox"
import classes from "./TransactionImportAddCategory.module.css"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import { Chip, ListItem, Paper } from "@mui/material"
import fetcher from "../../utils/fetchAuthorize"
import FetchError from "../../utils/fetchError"
import { baseURL } from "../../properties"

const TransactionImportAddCategory = ({
  isOpen,
  setIsOpen,
  categoryId,
  setSubcategoriesHandler
}) => {
  // const [isOpen, setIsOpen] = useState(false)
  const [subcategories, setSubcategories] = useState(
    useLoaderData()[categoryId - 1].map((subcategory) => ({
      ...subcategory,
      label: subcategory.name,
      isChanged: false,
      saveChanges: true,
      selected: subcategory.active
    }))
  )

  // store the initial state of the subcategories
  const initialSubcategories = useRef(subcategories)

  const saveCategoriesHandler = async () => {
    // Ids of new subs that need saved for good
    let subcategoryIdsToSave = []
    // Ids of subs that were removed for good
    let subcategoryIdsToDelete = []
    // subs that will be shown after the save
    let subcategoriesToDisplay = []

    const updatedSubcategories = subcategories.map((subcategory) => {
      const isSelected = subcategory.selected
      const needsUpdate = subcategory.saveChanges && subcategory.isChanged

      if (isSelected) {
        subcategoriesToDisplay.push(subcategory)
        if (needsUpdate) {
          subcategoryIdsToSave.push(subcategory.id)
        }
        return { ...subcategory, active: true, isChanged: false } // Always reset isChanged
      } else {
        if (needsUpdate) {
          subcategoryIdsToDelete.push(subcategory.id)
        }
        return { ...subcategory, active: false, isChanged: false } // Always reset isChanged
      }
    })

    const params = new URLSearchParams({
      subcategoryIdsToSave: subcategoryIdsToSave.join(","),
      subcategoryIdsToDelete: subcategoryIdsToDelete.join(",")
    })

    const response = await fetcher(
      `${baseURL}updateUserSubcategories?${params}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        }
      }
    )

    if (!response.ok) {
      throw new FetchError.fromResponse(response)
    }

    setSubcategoriesHandler(subcategoriesToDisplay)
    setSubcategories(updatedSubcategories)
    setIsOpen(false)
  }

  // reset the subcategories to the initial state when the dialog is closed without saving
  const onCloseHandler = () => {
    setIsOpen(false)
    setSubcategories(initialSubcategories.current)
  }

  const setSaveChanges = (e, value) => {
    e.stopPropagation()

    // update the save changes property of the subcategory
    setSubcategories((prevState) => {
      return prevState.map((subcategory) => {
        if (subcategory.id === value.id) {
          return {
            ...subcategory,
            saveChanges: !subcategory.saveChanges
          }
        }
        return subcategory
      })
    })
  }

  const selectCategoryHandler = (value) => {
    setSubcategories((prevState) => {
      return prevState.map((subcategory) => {
        if (subcategory.id === value.id) {
          return {
            ...subcategory,
            isChanged: !subcategory.isChanged,
            selected: !subcategory.selected
          }
        }
        return subcategory
      })
    })
  }

  const background = (isActive, isChanged) => {
    return isChanged && isActive ? "rgba(200, 10, 10, 0.10)" : undefined
  }
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      onClose={onCloseHandler}
      className={classes.container}
      PaperProps={{
        sx: {
          maxWidth: "100%"
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">
        Select categories
        <Paper
          sx={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "row",
            flexWrap: "wrap",
            p: 0.5,
            m: 0
          }}
          component="ul"
        >
          {subcategories
            .filter((subcategory) => subcategory.selected)
            .map((subcategory) => (
              <ListItem
                key={subcategory.id}
                sx={{ padding: "2px", width: "auto" }}
              >
                <Chip
                  size="small"
                  label={subcategory.name}
                  onDelete={() => selectCategoryHandler(subcategory)}
                />
              </ListItem>
            ))}
        </Paper>
      </DialogTitle>
      <DialogContent>
        <ol style={{ padding: 0, margin: 0 }}>
          {subcategories.map((subcategory) => (
            <Box key={subcategory.id}>
              <li
                style={{
                  listStyleType: "none",
                  display: "flex",
                  background: background(
                    subcategory.active,
                    subcategory.isChanged
                  )
                }}
                onClick={() => selectCategoryHandler(subcategory)}
              >
                <Checkbox
                  style={{ marginRight: 8 }}
                  size="small"
                  checked={subcategory.selected}
                />
                <Box sx={{ padding: "0 0 5px 0" }}>
                  <Typography variant="subtitle1" sx={{}}>
                    {subcategory.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {subcategory.description}
                  </Typography>
                </Box>
                {subcategory.isChanged && (
                  <FormControlLabel
                    sx={{ width: "175px" }}
                    control={
                      <Switch
                        defaultChecked
                        onClick={(e) => setSaveChanges(e, subcategory)}
                      />
                    }
                    label={
                      <Typography variant="caption">
                        Save changes for future use
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                )}
              </li>
              <Divider variant="middle" />
            </Box>
          ))}
        </ol>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="warning"
          size="small"
          onClick={onCloseHandler}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={saveCategoriesHandler}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default TransactionImportAddCategory
