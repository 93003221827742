import { useCallback } from "react"
import { Divider, IconButton, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import { usdFormatter } from "../utils/usdFormatter"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import classes from "./SubcategoryList.module.css"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Collapse from "@mui/material/Collapse"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useRecoilState } from "recoil"
import { activeIdState, highlightedCategoryState } from "../state/state"
import { setObjectBoolean } from "../state/stateUtils"

const StyledListItemButton = styled(ListItemButton)(({ theme, props }) => ({
  marginLeft: 25,
  borderRadius: 8,
  height: "30px",
  "&.active": {
    backgroundColor: props.hoverColor
  },
  "&:hover": {
    backgroundColor: props.hoverColor
  },
  "&.Mui-selected": {
    backgroundColor: props.selectedColor
  },
  "&.Mui-selected:hover": {
    backgroundColor: props.hoverColor
  }
}))

// classes used for styling the card when expanded
const expandClasses = (isExpanded) =>
  `${classes["expandMore"]} ${isExpanded ? classes["expandMoreExpanded"] : ""}`

const SubcategoryList = ({ category, setter }) => {
  const [activeId, setActiveId] = useRecoilState(activeIdState)
  const [highlightedCategory, setHighlightedCategory] = useRecoilState(
    highlightedCategoryState
  )

  const clickHandler = useCallback(
    (node) => {
      if (highlightedCategory !== null && highlightedCategory.id === node.id) {
        // If clicking the already active node, deactivate it
        setHighlightedCategory(null)
      } else {
        // Otherwise, update highlightedCategory and let onActiveIdChange handle setting activeId
        setHighlightedCategory({
          id: node.id,
          name: node.label,
          total: node.value,
          previousTotal: node.previousTotal,
          colors: node.barColor,
          transactions: node.transactions ?? []
        })
      }
    },
    [highlightedCategory, setHighlightedCategory]
  )

  const mouseEnterHandler = useCallback(
    (e) => {
      if (!highlightedCategory) setActiveId(e.target.id)
    },
    [highlightedCategory, setActiveId]
  )

  const mouseLeaveHandler = useCallback(() => {
    if (!highlightedCategory) setActiveId(null)
  }, [highlightedCategory, setActiveId])

  const isSelected = useCallback(
    (item) =>
      highlightedCategory !== null && item.id === highlightedCategory.id,
    [highlightedCategory]
  )

  const events = (item) => ({
    onClick: () => clickHandler(item),
    onMouseEnter: mouseEnterHandler,
    onMouseLeave: mouseLeaveHandler,
    selected: isSelected(item)
  })

  return (
    <FormGroup className={classes.container}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={category.isSelected}
              onChange={() => {
                setter(
                  setObjectBoolean(category, "isSelected", !category.isSelected)
                )
              }}
              sx={{
                "&.Mui-checked": {
                  color: category.hexColor
                }
              }}
            />
          }
          label={
            <>
              <Typography
                className={classes.reducedLineHeight}
                variant="subtitle2"
              >
                {category.name}
              </Typography>
              <Typography
                className={classes.reducedLineHeight}
                variant="caption"
              >
                {usdFormatter(category.total)}
              </Typography>
            </>
          }
        />
        <IconButton
          className={expandClasses(category.isExpanded)}
          aria-expanded={category.isExpanded}
          aria-label="show more"
          onClick={() => {
            setter(
              setObjectBoolean(category, "isExpanded", !category.isExpanded)
            )
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <Divider variant="middle" />
      <Collapse in={category.isExpanded} timeout="auto" unmountOnExit>
        <List dense={true} className={classes.subcategoryList}>
          {category.categories.map((item, index) => (
            // <Tooltip
            //   arrow
            //   placement="right"
            //   key={index}
            //   title={
            //     <Typography variant="subtitle1">
            //       {usdFormatter(item.total)}
            //     </Typography>
            //   }
            // >
            <StyledListItemButton
              className={item.id === activeId ? "active" : ""}
              key={index}
              id={item.id}
              {...events(item)}
              props={{
                selectedColor: item.color,
                hoverColor: item.hoverColor
              }}
            >
              <ListItemText disableTypography className={classes.text}>
                <Typography variant="body2">{item.label}:</Typography>
                <Typography variant="body2">
                  <em>
                    <b>{usdFormatter(item.total)}</b>
                  </em>
                </Typography>
              </ListItemText>
              {/* <ListItemText primary={item.label} /> */}
            </StyledListItemButton>
            // </Tooltip>
          ))}
        </List>
      </Collapse>
    </FormGroup>
  )
}
export default SubcategoryList
