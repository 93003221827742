import TextField from "@mui/material/TextField"
import classes from "./InputFields.module.css"

const MessageInput = ({ message, setMessageHandler, error }) => {
  return (
    <TextField
      multiline
      rows={8}
      required
      label="Message"
      value={message}
      onChange={(e) => setMessageHandler(e.target.value)}
      className={classes.inputField}
      error={error.isError}
      helperText={error.message}
      sx={{
        // Target the inner textarea for scrollbar styles
        "& .MuiInputBase-inputMultiline": {
          overflow: "auto", // Ensure the overflow is set for scrollbar to appear

          // Scrollbar styles
          "&::-webkit-scrollbar": {
            width: "7px"
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "rgba(119, 119, 119, 0.25)",
            borderRadius: "8px"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(119, 119, 119, 0.7)",
            borderRadius: "8px"
          }
        }
      }}
    />
  )
}

MessageInput.defaultProps = {
  error: { isError: false, message: "" }
}

export default MessageInput
